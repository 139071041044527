import { Button, Stack, Box, Icons, Text } from '@goodhuman-me/components';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestStatus,
} from 'utilities/enum-utils';
import AvailabilityUnavailabilityRequestModal from '../../../availability/components/AvailabilityUnavailabilityRequestModal';
import AvatarNeutralCrossIcon from '../avatar-neutral-cross-icon';
import EnvelopeIcon from '../envelope-icon';

type UnavailabilityRequestsProps = {
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
};
const UnavailabilityRequests = (props: UnavailabilityRequestsProps) => {
  const { memberAvailabilities, doGetTimeAvailability } = props;

  const [showModal, setModal] = useState(false);

  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.scheduledUnavailability.requests' });

  const totalNewRequests = memberAvailabilities.scheduledUnavailabilityRequest.filter(
    (item) =>
      item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL ||
      item.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION,
  ).length;

  return (
    <>
      {showModal && (
        <AvailabilityUnavailabilityRequestModal
          type={AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY}
          memberAvailabilities={memberAvailabilities}
          onClose={() => {
            setModal(null);
            doGetTimeAvailability({});
          }}
        />
      )}

      <Stack flex="1" justifyContent="center" alignItems="center" paddingX="$large" paddingY="$large" gap="$medium">
        {totalNewRequests > 0 ? <EnvelopeIcon /> : <AvatarNeutralCrossIcon />}
        <Text fontWeight="bold">{t('label')}</Text>
        <Text color="$bodyDark1">{t('totalNew', { count: totalNewRequests })}</Text>
        {totalNewRequests > 0 && (
          <Box>
            <Button onClick={() => setModal(true)} emphasis="quiet">
              {t('viewRequests')}
              <Icons.ChevronRight width="1em" />
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  memberAvailabilities: state.teamStore.memberAvailabilities,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
});

export default connect(mapStateToProps, mapDispatchToProps)(UnavailabilityRequests);
